<template>
  <div>
    <headertop Inv="1" ref="headertop"></headertop>
    <goodslist ref="goodslist" @addCarSuccess="addCarSuccess"></goodslist>
    <bottom></bottom>
  </div>
</template>

<script>
import headertop from "../components/header.vue";
import goodslist from "../components/goodslist.vue";
import bottom from "../components/bottom.vue";

export default {
  name: "home",
  components: {
    headertop,
    goodslist,
    bottom
  },
  data() {
    return {
      id: '',
      collectStatus: ''
    };
  },
  created() {
    if (this.$route.query.id) {
      this.id = this.$route.query.id;
      localStorage.setItem("onlineId", this.id);
    }
 
    localStorage.setItem("loginType", 1);
    

  },

  mounted() {

    // if (this.$route.query.collectStatus) {
    //   this.$refs.goodslist.getShoucangList()
    // }
  },

  methods: {
    addCarSuccess(){
      this.$refs.headertop.getPrice()
    }
  },
};
</script>

<style lang="scss" scoped></style>
